@media(min-width:1367px) and (max-width:1920px) {}

@media(max-width:1680px) {

    
}

@media(max-width:1499px) {
    
}

@media(max-width:1440px) {
    
}

@media(max-width: 1399px) {
    .hero-img {
        right: 0px;
    }
    .element5 {
        right: 0px;
    }
    .feature-arrow {
        max-width: 290px;
    }
    .feature-arrow-left {
        bottom: -170px;
    }
    .feature-arrow-right {
        right: -130px;
    }
    .process-content-row {
        padding: 0 0px 0 0px;
    }
    .direction-rtl .feature-arrow-right {
        left: -135px;
        right: auto;
    }
}

@media(max-width:1199px) {
    header nav.navbar ul {
        gap: 4px;
    }
    header nav.navbar ul li a.nav-link {
        padding: 6px 15px !important;
    }
    .header-section-content {
        padding-left: 0px;
    }
    .element1 {
        left: -60px;
    }
    .element2 {
        left: -160px;
    }
    .element3 {
        top: 60px;
    }
    .about-img {
        max-width: 100%;
    }
    .feature-img-col .feature-img-1, .feature-img-col .feature-img-2, .feature-img-col .feature-img-3{
        width: 100%;
    }
    .feature-arrow-right {
        right: -80px;
        bottom: -230px;
    }
    .process-icon-wrapper img.process-arrow{
        display: none;
    }
    .primary-text-hiw {
        padding-left: 0;
        padding-right: 0;
    }
    .contact-content{
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    
}

@media(max-width:991px) {
    header nav.navbar ul {
        gap: 0px;
    }

    .header-section-content {
        padding-top: 50px;
    }
    .header-content {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    header {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
        -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
        -moz-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    .common-section {
        padding: 40px 0 50px 0;
    }
    
    .header-logo {
        flex: 1;
    }

    .header-content {
        gap: 0px;
    }
    .navbar-toggler {
        display: inline-block;
    }
    header nav.navbar {
        position: absolute;
        top: 57px;
        left: 0;
        right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    header nav.navbar .navbar-collapse {
        background-color: #F9F9F9;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        border-top: 1px solid #d5d5d5;
        border-bottom: 1px solid #d5d5d5;
    }

    header nav.navbar ul li a.nav-link {
        padding-right: 0.7rem !important;
        padding-left: 0.7rem !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        width: 100%;
        color: var(--blue);

    }

    header nav.navbar ul li a.nav-link.active {
        color: var(--blue) !important;
        font-family: var(--bold);
        background-color: #DDD;
    }

    header nav.navbar ul li a.nav-link:not(.active):hover {
        color: var(--white) !important;
        background-color: var(--blue);

    }
    header nav.navbar ul li a::after, header nav.navbar ul li a:hover::after{
        display: none !important;
    }

    header nav.navbar ul li {
        border-bottom: 1px solid #d5d5d5;
    }

    header nav.navbar ul li:last-child {
        border-bottom: none;

    }

    header nav.navbar ul li a.active::after {
        display: none;
    }
    header nav.navbar ul li a:hover::after{
        display: none;
    }
    
    .banner-area {
        height: auto;
        min-height: unset;
        max-height: none;
    }
    .header-section-content {
        flex-wrap: wrap;
        padding-top: 20px;
    }
    .header-section-content-wrapper{
        flex-direction: row;
    }
    .hero-img {
        display: none;
    }
    .header-section-content {
        max-width: 100%;
        justify-content: center;
    }
    .header-section-content > *{
        text-align: center;
    }
    .apps-download{
        justify-content: center;
    }
    .scoller-btn{
        display: none;
    }
    .social-hero{
        justify-content: center;
        margin-top: 30px;
    }
    .element4 {
        top: 10px;
        right: -220px;
    }
    .element2 {
        top: 250px;
    }
    .element6 {
        left: 170px;
    }
    .element5 {
        top: 280px;
    }
    .element3{
        display: none;
    }
    .about-img {
        max-width: 400px;
        margin-top: 50px;
        width: 100%;
    }
    .primary-heading{
        text-align: center;
    }
    .secondary-heading{
        text-align: center;
    }
    .primary-text{
        text-align: center;
    }
    .about-content-col{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .element7{
        display: none;
    }
    .feature-img-col .feature-img-1, .feature-img-col .feature-img-2, .feature-img-col .feature-img-3 {
        width: 100%;
        max-width: 450px;
    }
    .feature-arrow{
        display: none;
    }
    .feature-img{
        margin-top: 30px;
    }
    .col-feature {
        padding-bottom: 0px;
        padding-top: 40px;
    }
    #features{
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
    }
    .common-primary-heading {
        margin-bottom: 20px;
    }
    .primary-text-all {
        margin-top: 20px;
    }
    .process-content-row {
        margin-top: 30px;
    }
    .process-heading {
        margin-top: 20px;
    }
    .process-content-col{
        margin-bottom: 40px;
    }
    .contct-img{
        display: none;
    }
    .contact-content{
        margin-top: 30px;
    }
    .footer-content-inner{
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer-content-inner .footer-nav{
        padding: 0;
        width: 100%;
    }
    .footer-content-inner .footer-nav ul{
        width: 100%;
        justify-content: center;
    }
    .footer-copyright{
        width: 100%;
        justify-content: center;
    }
    .followContainer{
        width: 100%;
        justify-content: center;
    }
    #dropdown-lang{
        height: 37px;
    }
    .primary-heading{
        width: 100%;
        justify-content: center;
    }
    
}

@media(max-width:767px) {
    .element6 {
        left: 100px;
    }
    .primary-heading {
        font-size: 35px;
    }
    .secondary-heading {
        font-size: 22px;
    }
    .app-on-heading {
        font-size: 22px;
    }
    .banner-container > .row {
        width: unset;
        margin-left: calc(-.5 * var(--bs-gutter-x));
        margin-right: calc(-.5 * var(--bs-gutter-x));
    }
    .banner-area{
        padding-left: 0;
        padding-right: 0;
    }
}

@media(max-width:640px) {
    
}

@media (max-width: 575px) {
    .footer-copyright{
        flex-wrap: wrap;
    }
    .copyrightContainer{
        width: 100%;
        justify-content: center;
        margin: 0 0 5px 0px;
    }
    .footer-content-inner .footer-nav ul{
        gap: 0;
    }
    .copyrightContainer::after{
        display: none;
    }
    .footer-nav ul li a {
        padding: 0px 3px;
    }
    .footer-content{
        padding: 0;
    }
    .footer-wrapper > .container{
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width:480px) {
    .element{
        display: none;
    }
    .header-logo img {
        max-width: 100px;
    }

}

@media (max-width:420px) {
    
    

}

@media (max-width:380px) {

    

}
@media (max-width:340px) {

    

}
@media (max-width:320px) {

    

}

/***************************Direction RTL***************************/


@media (max-width: 1680px) {



}

@media(max-width: 1399px) {
    
}

@media(max-width:1199px) {
    .direction-rtl .feature-arrow-left {
        right: -170px;
        bottom: -150px;
    }
}

@media(max-width:991px) {
    .direction-rtl .contct-img-ar{
        display: none;
    }
    .direction-rtl .copyrightContainer::after {
        left: 0;
        right: auto;
    }
}

@media(max-width:767px) {

    
}

@media (max-width: 567px) {
    


}

@media (max-width: 480px) {
    
    
}

@media (max-width:420px) {

    

}

@media (max-width: 380px) {
    
    


}

@media (max-width: 320px) {

    
}