
@font-face {
    font-family: 'Inter-light';
    src: url('../fonts/Inter-Light.woff2') format('woff2'),
        url('../fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-medium';
    src: url('../fonts/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-semiBold';
    src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter-bold';
    src: url('../fonts/Inter-Bold.woff2') format('woff2'),
        url('../fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter-extraBold';
    src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('../fonts/Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter-black';
    src: url('../fonts/Inter-Black.woff2') format('woff2'),
        url('../fonts/Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



/****************Arabic Fonts***************/

@font-face {
    font-family: 'Noto Kufi Arabic Thin';
    src: url('../fonts/arabic/NotoKufiArabic-Thin.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic ExtraLight';
    src: url('../fonts/arabic/NotoKufiArabic-ExtraLight.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic Light';
    src: url('../fonts/arabic/NotoKufiArabic-Light.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic Regular';
    src: url('../fonts/arabic/NotoKufiArabic-Regular.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic Medium';
    src: url('../fonts/arabic/NotoKufiArabic-Medium.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic SemiBold';
    src: url('../fonts/arabic/NotoKufiArabic-SemiBold.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic Bold';
    src: url('../fonts/arabic/NotoKufiArabic-Bold.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic ExtraBold';
    src: url('../fonts/arabic/NotoKufiArabic-ExtraBold.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Noto Kufi Arabic Black';
    src: url('../fonts/arabic/NotoKufiArabic-Black.woff2') format('woff2'),
        url('../fonts/arabic/NotoKufiArabic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}





/* Variables Start */

:root {
    --blue: #1DA1F2;
    --lightBlue: #d7effe;
    --black: #272727;
    --white: #ffffff;
    --red: #FC5A5A;
    --lightGreen: #2BCCD7;
    --red2: #EF4862;
    --orange: #FF7C18;
    --grey: #707070;

    
    --light: "inter-light";
    --regular: "inter-regular";
    --medium: "inter-medium";
    --semiBold: "inter-semiBold";
    --bold: "inter-bold";
    --extraBold: "inter-extrabBold";
    --tBlack: "inter-black";



    --arThin: "Noto Kufi Arabic Thin";
    --arExtraLight: "Noto Kufi Arabic ExtraLight";
    --arLight: "Noto Kufi Arabic Light";
    --arRegular: "Noto Kufi Arabic Regular";
    --arMedium: "Noto Kufi Arabic Medium";
    --arSemiBold: "Noto Kufi Arabic SemiBold";
    --arBold: "Noto Kufi Arabic Bold";
    --arExtraBold: "Noto Kufi Arabic ExtraBold";
    --arBlack: "Noto Kufi Arabic Black";
}

/******************Text All**********/

.__regular,
.__regular>* {
    font-family: var(--regular);
}

body.direction-rtl .__regular,
body.direction-rtl .__regular>* {
    font-family: var(--arRegular);
}

.__medium,
.__medium>* {
    font-family: var(--medium);
}

body.direction-rtl .__medium,
body.direction-rtl .__medium>* {
    font-family: var(--arMedium);
}

.__semiBold,
.__semiBold>* {
    font-family: var(--semiBold);
}

body.direction-rtl .__semiBold,
body.direction-rtl .__semiBold>* {
    font-family: var(--arsemiBold);
}

.__bold,
.__bold>* {
    font-family: var(--bold);
}

body.direction-rtl .__bold,
body.direction-rtl .__bold>* {
    font-family: var(--arbold);
}

.__extraBold,
.__extraBold>* {
    font-family: var(--bold);
}

body.direction-rtl .__extraBold,
body.direction-rtl .__extraBold>* {
    font-family: var(--arbold);
}


.bg-blue{
    background-color: var(--blue);
}

.bg-red{
    background-color: var(--red2);
}

.bg-green{
    background-color: var(--lightGreen);
}

.bg-orange{
    background-color: var(--orange);
}



/* Variables end */
.bgGreen {
    background-color: var(--blue);
}

.bgWhite {
    background-color: var(--white);
}



/* .text-primary {
   color: var(--primaryText);
}
.bg-secondary {
   background-color: var(--secondaryText);
} */



html {
    width: 100%;
    /*height: 100%;*/
}

body {
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    color: var(--black);
    background-color: var(--white);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--bold);
    letter-spacing: .5px;
    margin-top: 0
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
    font-family: var(--semiBold);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;

}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: var(--orange);
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

p {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 6px
}

/*.container {
   width: 1200px
}
.container-fluid {
   margin-left: -15px;
   margin-right: -15px
}*/
.f-left {
    float: left
}

.f-right {
    float: right
}

.fix {
    overflow: hidden
}

/*a img {
    -webkit-transition: .3s;
    transition: .3s
}

a img:hover {
    opacity: .9;
    -ms-filter: "alpha(opacity=90)";
}*/

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

a:focus,
a:hover {
    text-decoration: none
}

a,
button {
    outline: medium;

}

h1 {
    font-size: 35px;
    font-weight: 500
}

h2 {
    font-size: 30px;
    font-weight: 600
}

h3 {
    font-size: 18px
}

h4 {
    font-size: 15px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 11px
}

.text-uppercase {
    text-transform: uppercase
}



label {
    color: #858585;
    /* cursor: pointer; */
    font-size: 14px;
    font-weight: 400
}

*::-moz-selection {
    background: var(--blue);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--blue) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--blue);
    color: #fff;
    text-shadow: none
}

*::-moz-placeholder {
    font-size: 14px;
    color: #c7c7c7 !important;
}

*::-webkit-input-placeholder {
    color: #c7c7c7 !important;
    font-size: 14px
}

*:-ms-input-placeholder {
    color: #c7c7c7 !important;
    font-size: 14px
}

*::placeholder {
    color: #c7c7c7 !important;
    font-size: 14px
}

.text-center {
    text-align: center
}

.textBlack {
    color: var(--black) !important;
}

.textOrange {
    color: var(--orange) !important;
}


.d-flex {
    display: flex;
}


.btn {
    padding: 0 25px;
    height: 40px;
    line-height: 40px;
    color: var(--white);
    border: transparent;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    /* min-width: 120px; */
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}


/* .btn.btn-primary {
    background-color: var(--blue);
}

.btn.btn-primary:hover {
    background-color: #529c20;
} 

.btn.btn-secondary {
    background-color: #fff;
    border: 1px solid var(--blue);
    color: var(--blue);
}

.btn.btn-secondary:hover {
    background-color: var(--blue);
    color: #fff;
}*/





img {
    max-width: 100%;
}

.form-group {
    margin-bottom: 15px;
    position: relative;
}


.form-group label {
    font-size: 15px;
    padding-bottom: 3px;
    font-family: var(--medium);
    color: var(--black);
}

.form-group .form-control {
    border-radius: 6px;
    background-color: #fff;
    border: 0;
    padding: 8.5px 15px 8.5px 15px;
    color: var(--black);
    width: 100%;
    margin-bottom: .5rem;
    font-size: 14px;
    font-family: var(--regular);
    height: 46px;
    box-shadow: 0px 9px 30px -19px rgb(29, 161, 242, .7);
    -webkit-box-shadow: 0px 9px 30px -19px rgb(29, 161, 242, .7);
    -moz-box-shadow: 0px 9px 30px -19px rgb(29, 161, 242, .7);
}

.form-group textarea.form-control {
    min-height: auto;
    height: 150px;
    overflow-y: hidden;
    resize: none;
}





.main-wrapper {
    /*display: flex;
   flex-grow: 1;*/
    width: 100%;
    height: 100%;
    background-color: var(--black);
}

.header-area {
    position: fixed;
    width: 100%;
    z-index: 105;
    height: 64px;
}

.header-area>.container-fluid>.row {
    flex-grow: 1;
}

.right-menu .navbar-toggler {
    display: none;
}

.navbar-light .navbar-toggler {
    color: rgb(0, 0, 0);
    border-color: rgba(0, 0, 0, 0.5);
}


.d-flex {
    display: flex;
}

.btn {
    padding: 0 18px;
    height: 46px;
    line-height: 46px;
    font-family: var(--regular);
    color: #fff;
    border: transparent;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    min-width: 120px;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

.btn.btn-primary {
    background-color: var(--blue);
}

.btn.btn-primary:hover {
    background-color: var(--red);
}

.btn.btn-secondary {
    background-color: transparent;
    border: 1px solid var(--secondaryText);
    color: var(--secondaryText);
    align-items: center;
    gap: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.btn.btn-secondary:hover {
    background-color: var(--primaryText);
    color: #fff;
}

.btn.work-with-us img {
    width: 21px;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--white);
}

.header-content {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: space-between;
}

.header-logo img {
    max-width: 150px;
    height: auto;
}

.navbar-toggler {
    display: none;
}

.logo {
    font-size: 25px;
    line-height: 64px;
    display: flex;
    width: auto;
    max-width: auto;
    align-items: center;
    height: 100%;
}

.logo svg {
    fill: #1a73e8;
    width: 165px;
}

header nav.navbar {
    display: flex;
    padding: 0 1rem;
}

header nav.navbar ul {
    display: flex;
}

header nav.navbar ul li {
    position: relative;
}

header nav.navbar ul li a {
    padding: 0 12px 0 12px;
    color: var(--black);
    display: inline-block;
    font-size: 15px;
    font-family: var(--regular);
}

header nav.navbar ul li a:focus {
    color: #fff;
}

header nav.navbar ul li a:hover:not(.active) {
    background-color: transparent;
    border-radius: 4px;
    color: var(--blue);
}

header nav.navbar ul li.active a {
    color: var(--blue) !important;
    font-family: var(--semiBold);
}

header nav.navbar ul li.active a::after {
    background-color: var(--blue);
    bottom: -12px;
    content: ' ';
    height: 3px;
    left: 0.7rem;
    margin: auto;
    position: absolute;
    width: 20px;
    z-index: 900;
    border-radius: 2px;
}

header nav.navbar ul li a:hover::after {
    background-color: var(--blue);
    bottom: -12px;
    content: ' ';
    height: 3px;
    left: 0.7rem;
    margin: auto;
    position: absolute;
    width: 20px;
    z-index: 900;
    border-radius: 2px;
}

header nav.navbar ul li a.nav-link {
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
}

header nav.navbar ul li.item-work {
    display: none;
}
.navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(7, 5, 42)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
    ;
}

.dropdown-lang .btn {
    background-color: rgba(25, 45, 2216, .6);
    border: 1px solid #1918D8;
}

.rightNavBtn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-lang .btn:hover {
    color: #fff;
}

.work-lang-btns {
    display: flex;
    gap: 10px;
}

.call-box {
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-us {
    display: flex;
    align-items: center;
    gap: 7px;
}

.bootstrap-select .btn-default {
    background-color: var(--bg);
    border: 1px solid var(--bg);
    color: var(--blue);
}
/* 
.bootstrap-select .flag-icon-squared {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    border-radius: 50%;
}

.flag-icon-squared {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    border-radius: 50%;
}

.bootstrap-select .flag-icon-squared.flag-icon-au {
    background-size: 49px;
    background-position: top left -5px;
} */

.dropdown-menu.open.show .dropdown-menu.inner.selectpicker {
    display: inline-block;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    display: flex !important;
    gap: 10px;
    align-items: center;
}

/* .bootstrap-select .btn-default:hover{
   color: #fff;
} */
.dropdown-menu.open.show .dropdown-menu.inner {
    display: block;
}

.dropdown-menu.open.show .dropdown-menu.inner li a .text {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.dropdown-menu.open.show .dropdown-menu.inner li {
    border-bottom: 1px solid #d5d5d5;
}

.dropdown-menu.open.show .dropdown-menu.inner li:last-child {
    border-bottom: none;
}

.dropdown-menu.open.show .dropdown-menu.inner li a {
    padding: 7px 10px 7px 10px;
    display: flex;
    color: var(--blue);
    font-family: var(--medium);
    text-decoration: none;
}

.dropdown-menu.open.show .dropdown-menu.inner li a:hover {
    background-color: #DDD;
}

.dropdown-toggle::after {
    display: none;
}

.btn-default-white {
    background-color: white;
    color: black;
}

.btn-default-white:hover {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

/* 
.dropdown-menu.open.show{
   padding-top: 0;
   padding-bottom: 0;
   bottom: 38px;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner{
   display: flex !important;
   gap: 10px;
   align-items: center;
}
.bootstrap-select .btn-default:hover{
   color: #fff;
}
.dropdown-menu.open.show .dropdown-menu.inner{
   display: block;
} 
.dropdown-menu.open.show .dropdown-menu.inner li a .text{
   display: flex;
   align-items: center;
   gap: 10px;
   width: 100%;
} 
.dropdown-menu.open.show .dropdown-menu.inner li{
   border-bottom: 1px solid #d5d5d5;
}
.dropdown-menu.open.show .dropdown-menu.inner li:last-child{
   border-bottom: none;
}
.dropdown-menu.open.show .dropdown-menu.inner li a{
   padding: 7px 10px 7px 10px;
   display: flex;
   color: var(--blue);
   font-family: var(--medium);
   text-decoration: none;
}
.dropdown-menu.open.show .dropdown-menu.inner li a:hover{
   background-color: #DDD;
} */
.bs-caret {
    display: none;
}

.col-menu-left.hdr-d-board {
    display: flex;
    align-items: center;
}

.navbar-nav .dropdown-menu.dropdown-menu-links {
    position: absolute;
    width: 200px;
    padding: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
}

.navbar-nav .dropdown-menu.dropdown-menu-links a.dropdown-item {
    border-bottom: 1px solid #eaeaea;
    font-size: 0.95rem;
    color: #80868b;
}

.navbar-toggler {
    padding: 0.25rem 0.55rem;
    border: 1px solid var(--primaryText);
    background-color: var(--primaryText);
    border-radius: 4px;
}

.navbar-toggler-icon {
    width: 1.5em;
    height: 1.5em;
}
header.sticky {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    -moz-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
}
.navbar-nav .nav-item{
    cursor: pointer;
}
.primary-heading{
    font-family: var(--tBlack);
    color: var(--blue);
    font-size: 45px;
}
.secondary-heading{
    font-family: var(--semiBold);
    color: var(--black);
    font-size: 26px;
}
.secondary-heading span{
    background-color: var(--red);
    color: var(--white);
}
.bg-heading{
    font-family: var(--semiBold);
    color: var(--blue);
    font-size: 26px;
}
.bg-heading span{
    display: inline-block;
    position: relative;
}
.bg-heading span::before{
    content: '';

    width: 100%;
    height: 100%;
    background-color: var(--red);
}
.app-on-heading{
    font-family: var(--semiBold);
    color: var(--white);
    font-size: 26px;
    margin-top: 7px;
    margin-bottom: 20px;
}
.app-on-heading span{
    background-color: var(--red);
}
.primary-text{
    color: var(--grey);
    font-size: 14px;
    line-height: 23px;
}

/********************Hero Section********************/

.banner-area {
    /* background-image: url(../images/bannerBg.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    min-height: 600px;
    position: relative;
    background-color: #ffffff;
    max-height: 650px;
}
.banner-area > div{
    height: 100%;
    padding-top: 60px;
}

.header-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 10;
}

.header-container>.row {
    /* height: 100%; */
    width: 100%;
}
.header-section-content{
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    padding-left: 80px;
}
.header-section-content > *{
    width: 100%;
}
.header-section-content-wrapper {
    float: left;
    width: 100%;
    position: relative;
}
.banner-container{
    display: flex;
    height: 100%;
    align-items: center;
}
.banner-container > .row{
    width: inherit;
    margin-left: 0;
    margin-right: 0;
}
.apps-download{
    display: flex;
    gap: 10px;
    margin-top: 30px;
}
.apps-download img{
    width: 135px;
}
.hero-img{
    position: absolute;
    right: 70px;
    width: 500px;
    top: -30px;
    bottom: 0;
}
.element{
    position: absolute;
}
.element1{
    top: 50px;
    left: -20px;
    width: 60px;
}
.element2{
    top: 300px;
    left: -120px;
    width: 120px;
}
.element3{
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 75px;
}
.element4{
    top: -30px;
    width: 185px;
    right: -160px;
}
.element5{
    top: 430px;
    width: 50px;
    right: 70px;
}
.element6{
    top: 370px;
    width: 25px;
    left: 300px;
}
.social-hero{
    display: flex;
    gap: 15px;
    margin-top: 80px;
}
.social-hero a{
    display: flex;
    border-radius: 50%;
    box-shadow: 0px 23px 21px -15px rgb(29, 161, 242);
    -webkit-box-shadow: 0px 23px 21px -15px rgb(29, 161, 242);
    -moz-box-shadow: 0px 23px 21px -15px rgb(29, 161, 242);
}
.social-hero a img{
    width: 45px;
}
.scoller-btn{
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.scoller-btn span{
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: var(--blue);
    letter-spacing: 5px;
    text-transform: uppercase;
}
.primary-text-all{
    margin-top: 30px;
}
.common-section{
    padding-top: 80px;
    padding-bottom: 80px;
}
/**********************About******************/
.about-row{
    align-items: center;
    position: relative;
}
.about-img{
    max-width: 500px;
    margin-top: 30px;
}
.about-img-col{
    text-align: center;
    
}

button.about-btn{
    padding: 10px 15px 10px 15px;
    background-color: #fff;
    border: 1px solid var(--blue);
    color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-radius: 10px;
    margin-top: 30px;
    box-shadow: 0px 11px 20px -8px rgb(29, 161, 242, .5);
    -webkit-box-shadow: 0px 11px 20px -8px rgb(29, 161, 242, .5);
    -moz-box-shadow: 0px 11px 20px -8px rgb(29, 161, 242, .5);
}
button.about-btn img{
    width: 11px;
    
}
.element7{
    right: 0;
    bottom: 0;
    width: 100px;
}
.primary-heading span{
    background-color: var(--red);
    color: #fff;
}
.common-primary-heading{
    margin-bottom: 50px;
}

/***********************Features********************/
.list-common{
    display: flex;
    color: var(--grey);
    font-size: 14px;
    flex-wrap: wrap;
    margin-top: 30px;
}
.list-common li{
    display: flex;
    align-items: flex-start;
    margin-left: -7px;
    width: 100%;
    gap: 15px;
    margin-bottom: 15px;
}
.listArrow-img{
    width: 30px;
}
.feature-img-col{
    text-align: center;
    position: relative;
}
.feature-img-col .feature-img-1{
    width: 500px;
}
.col-feature{
    padding-top: 80px;
    padding-bottom: 80px;
}
.feature-arrow{
    position: absolute;
    bottom: 0;
    max-width: 330px;
}
.feature-arrow-left{
    left: -150px;
    bottom: -200px;
}
.feature-arrow-right{
    right: -150px;
    left: auto;
    bottom: -200px;
}
.feature-row-reverse{
    flex-direction: row-reverse;
}
.feature-main-row{
    align-items: center;
}
.feature-img-2{
    width: 450px;
}
.feature-arrow-right{

}
.feature-img-3{
    width: 450px;
}


/*****************How it Works****************/

.primary-text-hiw{
    padding-left: 250px;
    padding-right: 250px;
}
.process-content-row{
    padding: 0 65px 0 65px;
    margin-top: 70px;
    margin-left: 0;
    margin-right: 0;
}
.process-content-col{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
}
.process-icon-wrapper{
    display: flex;
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 1;
    border-radius: 50%;
}
.process-icon-inner {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
    background-color: var(--theme-red);
}

.bg-blue .process-icon-inner {
    background-color: var(--theme-blue);
}
.process-icon-wrapper .process-icon-inner img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.process-icon-wrapper img.process-arrow{
    width: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -77px;
}


.process-icon-wrapper img{
    max-width: 60px;
    max-height: 60px;
}
.process-heading{
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: var(--semiBold);
    color: var(--grey);
    font-size: 19px;
    text-align: center;
    margin-top: 30px;
}


.process-icon-wrapper.bg-blue{
    background-color: rgba(29, 161, 242, 0.10);
}
.bg-blue .process-icon-inner{
    background-color: var(--blue);
}
.process-icon-wrapper.bg-red{
    background-color: rgba(239, 72, 98, 0.10);
}
.bg-red .process-icon-inner{
    background-color: var(--red2);
}
.process-icon-wrapper.bg-green{
    background-color: rgba(29, 161, 242, 0.10);
}
.bg-green .process-icon-inner{
    background-color: var(--lightGreen);
}
.process-icon-wrapper.bg-orange{
    background-color: rgba(43, 204, 215, 0.10);
}
.bg-orange .process-icon-inner{
    background-color: var(--orange);
}

.process-arrow{

}

/***********************Contact US********************/

.contact-wrapper{
    background-color: #F3FAFE;
}
.footer-logo{
    padding-top: 10px;
}
.footer-logo img{
    width: 170px;
}
.contact-content{
    padding-left: 50px;
    position: relative;
}
.contactDetailRow {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
}
.contct-img{
    position: absolute;
    bottom: -20px;
    left: 50px;
    max-width: 800px;

}
.contct-img-ar{
    display: none;
    position: absolute;
    bottom: -20px;
    right: 50px;
    max-width: 800px;
}
.contactDetailText{
    font-size: 14px;
    color: var(--black);
}

/*****************Footer***************/

.footer-wrapper{
    background-color: var(--blue);
}
.footer-logo {
    width: 100px;
}

.footer-content {
    display: flex;
    width: 100%;
}

.footer-content-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid var(--secondaryText);
    width: 100%;
}

.footer-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 80px;
    padding-right: 0px;
    gap: 0px;
    flex-direction: column;
    /* align-items: center; */
}

.footer-nav ul {
    display: flex;
    gap: 30px;
    width: fit-content;
}

.footer-nav ul li a {
    font-family: var(--regular);
    font-size: 14px;
    color: var(--white);
    text-decoration: none;
    padding: 0px 8px;
    cursor: pointer;
}
.footer-nav ul li a:not([href]){
    color: var(--white);
}

/* .footer-nav ul li a:focus {
    color: #fff;
} */

.footer-nav ul li a:hover {
    text-decoration: underline;
    color: var(--white);
}

.footer-copyright {
    display: flex;
    align-items: center;
    padding-top: 5px;
    font-family: var(--regular);
    font-size: 15px;
    color: var(--white);
    width: fit-content;
}

.copyrightContainer {
    display: flex;
    gap: 7px;
    align-items: center;
    position: relative;
    /* padding-right: 30px;
    margin-right: 30px; */
    margin: 0px 5px;
}

.footer-copyright a {
    font-family: var(--regular);
    font-size: 15px;
    color: var(--white);
    text-decoration: none;
}

.footer-copyright a:hover {
    text-decoration: underline;
}

.copyrightContainer::after {
    content: '';
    width: 1px;
    height: 20px;
    background-color: var(--white);
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0;
    margin: auto;
}
.footer_logo{
    max-width: 150px;
}

/* .phoneContainer {
    display: flex;
    align-items: center;
    padding-top: 5px;
    font-family: var(--regular);
    font-size: 15px;
    color: var(--secondaryText);
    white-space: nowrap;
    position: relative;
    padding-right: 20px;
    margin-right: 20px;
    gap: 7px;
} */

/* .phoneContainer img {
    width: 20px;
} */

/* .phoneContainer::after {
    content: '';
    width: 1px;
    height: 20px;
    background-color: var(--secondaryText);
    position: absolute;
    right: 0;
    top: -2px;
    bottom: 0;
    margin: auto;
} */

.developedContainer {
    display: flex;
    gap: 7px;
}

.followContainer {
    display: flex;
    gap: 15px;
    margin-left: auto;
    margin-right: 0;
}

.social-footer {
    display: flex;
    gap: 15px;
}

.social-footer a img {
    max-width: 20px;
    max-height: 20px;
}










/* .feature-content-col{
    display: flex;
    flex-wrap: wrap;

} */

/* .header-desc-text {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-family: var(--regular);
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    margin-top: 15px;
} */

/* .contact-btn-header {
    margin-top: 40px;
    position: relative;
}

.contact-btn img.plane1 {
    position: absolute;
    right: 170px;
    top: 36px;
    width: auto;
    max-width: none;
    height: 130px;
} */



/* .header-section-content > *{
   width: 100%;
} */


.btn#dropdown-lang{
    border:1px solid var(--blue);
    padding-left: 10px;
    padding-right: 30px;
    min-width: auto;
    position: relative;
    color: var(--blue);
}
.btn#dropdown-lang span:after{
    content: '';
    width: 15px;
    height: 9px;
    position: absolute;
    right: 9px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: url(../images/arrow_drop-lang.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px auto;
}
.btn#dropdown-lang:hover{
    background-color: var(--lightBlue);
}
.flag-icon-squared{
    width: 22px;
    height: 22px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    border-radius: 50%;
}
.flag-icon-sa.flag-icon-squared {
    background-image: url(../images/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
    background-image: url(../images/sa.svg);
}
.flag-icon-squared.flag-icon-au {
    background-size: 49px;
    background-position: top left -5px;
    background-image: url(../images/au.svg);
}
.select-lang .dropdown-menu{
    padding-top: 0;
    padding-bottom: 0;
}

.select-lang .dropdown-menu a{
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #d5d5d5;
    padding: 7px 10px 7px 10px;
}
.select-lang .dropdown-menu a:hover{
    background-color: #DDD;
}


/********************LTR********************/

.direction-rtl,
.direction-rtl .form-control,
.direction-rtl .footer-nav ul li a,
.direction-rtl .footer-copyright,
.direction-rtl .footer-copyright a {
    font-family: var(--arRegular);
}

.direction-rtl .btn,
.direction-rtl header nav.navbar ul li a.nav-link,
.direction-rtl .form-group label {
    font-family: var(--arMedium);
}

.direction-rtl .sidebar-nav ul.main-category-menu li.sidebar-list.active>a,
.direction-rtl .pageTitle,
.direction-rtl .notifyTitle,
.direction-rtl .common-title,
.direction-rtl .card-topbar-title,
.direction-rtl .label-heading,
.direction-rtl .label-common-heading,
.direction-rtl .summaryRow.cBlack,
.direction-rtl .timelineHeading,
.direction-rtl .secondary-heading,
.direction-rtl .app-on-heading,
.direction-rtl .process-heading {
    font-family: var(--arSemiBold);
}
.direction-rtl .primary-heading {
    font-family: var(--arBold);
}

.direction-rtl .btn#dropdown-lang{
    padding-right: 10px;
    padding-left: 30px;
}
.direction-rtl .btn#dropdown-lang span:after{
    right: auto;
    left: 9px;
}
.direction-rtl header nav.navbar ul li.active a::after {
    left: auto;
    right: 0.7rem;
}

.direction-rtl header nav.navbar ul li a:hover::after {
    left: auto;
    right: 0.7rem;
}
.direction-rtl .hero-img {
    right: auto;
    left: 70px;
}
.direction-rtl .element5 {
    left: 70px;
    right: auto;
}
.direction-rtl button.about-btn img, .direction-rtl .listArrow-img, .direction-rtl .contactDetailIconPhone img, .direction-rtl .feature-arrow-left, .direction-rtl .feature-arrow-right, .direction-rtl .process-arrow{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.direction-rtl .contactDetailTextPhone{
    direction: ltr;
}
.direction-rtl .scoller-btn span {
    letter-spacing: 0px;
}
.direction-rtl .feature-arrow-left {
    left: auto;
    right: -150px;
}
.direction-rtl .feature-arrow-right {
    left: -150px;
    right: auto;
}
.direction-rtl .process-icon-wrapper img.process-arrow {
    right: 215px;
}
.direction-rtl .contct-img {
    display: none;
}
.direction-rtl .contct-img-ar {
    display: block;
}